#root, #root > div.MuiGrid-root {
  min-height: 100vh;
}
body {
  background-color: #F6F6F6 !important;
}

.scroll::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.scroll {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  outline: none !important;
  border-color: #FFFFFF;
}

.scroll:focus {
  outline: none !important;
  border-color: #FFFFFF;
}